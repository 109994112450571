<template>
	<div style="background-color:#000000 ;min-height: 100%;">
		<div class="outerTable" v-for="(item,i) in data" :key='i'>
			<div class="unit_name">{{item.name}}</div>
			<div class="content">
				<table v-for="item2 of item.units">
					<tr v-for="item3 of item2.subs" @click="clickDanti(item3)" style="cursor: pointer;">
						<td style="width: 190px;">{{item3.unit_name}}</td>
						<td style="width: 80px;">{{arr[item3.type]}}</td>
						<td style="width: 100px;">{{item3.num}}{{item3.unit}}</td>
						<td style="width: 80px;" v-if="item3.alarm==-1||item3.alarm==-2"><img
								style="width: 20px;height: 20px;vertical-align: middle;"
								src="@assets/osImg/icon/jingbao-1.png" /></td>
						<td style="width: 80px;" v-else-if="item3.alarm==0"><img
								style="width: 20px;height: 20px;vertical-align: middle;"
								src="@assets/osImg/icon/jingbao0.gif" /></td>
						<td style="width: 80px;" v-else-if="item3.alarm==1"><img
								style="width: 20px;height: 20px;vertical-align: middle;"
								src="@assets/osImg/icon/jingbao1.gif" /></td>
						<td style="width: 80px;" v-else-if="item3.alarm==2"><img
								style="width: 20px;height: 20px;vertical-align: middle;"
								src="@assets/osImg/icon/jingbao2.gif" /></td>
						<td style="width: 80px;" v-else-if="item3.alarm==3"><img
								style="width: 20px;height: 20px;vertical-align: middle;"
								src="@assets/osImg/icon/jingbao3.gif" /></td>
					</tr>
				</table>
			</div>
		</div>

	</div>
</template>

<script>
	export default {
		name: "sbgk",
		data() {
			return {
				data: [],
				t: null,
				arr:{
					0:'振动',
					1:'转速',
					4:'wincc转速'
				}
			}
		},
		methods: {
			get() {
				this.$get("backend-api/web/unit/vib/overview").then((res) => {
					if (res.code != 1000) {
						return
					}
					this.data = res.data
				});
			},
			clickDanti(item) {
				// this.$emit("clickDanti", item);
				this.Bus.$emit("clickDanti",item)
				// this.$router.replace({
				// 	name: 'companydiagnosis'
				// });
			}
		},
		mounted() {
			this.get();
			this.t = setInterval(this.get, 60000);
		},
		beforeDestroy() {
			clearInterval(this.t);
		}
	}
</script>

<style lang="scss" scoped>
	.outerTable {
		width: 100%;
		border: 1px solid #595959;
		background-color: #000000;
		margin-bottom: 5px;
	}

	.unit_name {
		background-color: #404040;
		height: 40px;
		font-size: 14px;
		font-weight: bold;
		color: rgba(255, 255, 255, 0.85);

		padding-left: 30px;
		line-height: 40px;
	}

	.content {
		background-color: #262626;
	}

	table {
		margin: 30px;
		display: inline-block;
		border-radius: 10px;
		vertical-align: top;

		tr {
			td {
				background-color: #3c3c3c;
				text-align: center;
				font-size: 14px;
				// font-weight: bold;
				color: rgba(255, 255, 255, 0.85);
				line-height: 40px;
			}
		}
	}
</style>
